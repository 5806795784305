<template>
  <div class="zto-page-error">
    <div class="z-error-bg"><img :src="bgPic" alt="错误背景图"></div>
    <div class="z-error-body">
      <div class="z-error-pic"><img :src="errorPic" alt="错误图"></div>
    </div>
    <div class="z-error-footer">
      <h3 class="z-error-tip">{{errorMsg}}</h3>
      <el-button type="primary">
        <router-link to="/">{{linkMsg}}</router-link>
      </el-button>
    </div>
  </div>
</template>
<script>
import bgPic from '@/assets/error/500.png'
import errorPic from '@/assets/error/dd500.png'
export default {
  name: 'serverExc',
  data() {
    return {
      bgPic: bgPic,
      errorPic: errorPic,
      errorMsg: '服务器异常！请联系管理员！',
      linkMsg: '返回首页',
      code: 500
    }
  }
}
</script>
